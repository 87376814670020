
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import CreateUserSection from '@/components/sections/CreateUserSection.vue'

export default defineComponent({
  name: 'CreateUser',
  components: {
    Main,
    CreateUserSection
  },
  mounted() {
    const token = localStorage.getItem('phantom-token');
    if(token == null) {
      this.$router.push('/registration-phantom')
    }
  }
})
