
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useWallet } from "@/plugins/solana-wallets-vue";

export default defineComponent({
  name: "CreateUserSection",
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name"),
      email: yup.string().required("Email"),
    });

    return {
      isModalOpen: false,
      schema,
      data: {},
      subscribe: false,
      dataNames: {
        nameEmail: "Leave your name and email so we can contact you",
        subscribe: "Subscribe to newsletter",
        send: "Send",
        later: "Later",
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    createUser(data) {
      const { publicKey } = useWallet();
      this.$store
        .dispatch("auth/loginUser", {
          public_key: publicKey.value?.toBase58(),
          name: data.name,
          email: data.email,
          news_subscription: this.subscribe,
        })
        .then(
          (response) => {
            this.data = response;
            localStorage.setItem("user", JSON.stringify(this.data.access_token));
            this.$router.push("/email-confirmed");
          },
          (error) => {
            console.log(error);
          }
        );
    },
    skipStep() {
      const { publicKey } = useWallet();
      this.$store
        .dispatch("auth/loginUser", { public_key: publicKey.value?.toBase58() })
        .then((response) => {
          this.data = response;
          localStorage.setItem("user", JSON.stringify(this.data.access_token));
          this.$router.push("/dashboard/mint");
        });
    },
  },
});
